import React, { useState, useEffect, useRef } from 'react';
import { Menu,MenuList,ListItemIcon,TextField,IconButton,Tooltip,FormControl,MenuItem,Box,ListItemButton,List,ListItem,ListItemText,Divider,Dialog,DialogContentText,DialogTitle,DialogContent,DialogActions,Button,ButtonGroup,Typography,CircularProgress,Link } from '@mui/material';
import './Studio.css';
import { useTheme } from './ThemeContext';
import  AudioPlayer  from './AudioPlayer';
import { useLoading } from './LoadingContext';
import { useLanguage } from './LanguageContext';
import axios from 'axios';
import CustomTextField from './CustomTextField';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import ReactPlayer from 'react-player';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import VideoCameraFrontIcon from '@mui/icons-material/VideoCameraFront';
import VideoStableIcon from '@mui/icons-material/VideoStable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useSweetAlert from './SweetAlert';
import { Helmet } from 'react-helmet';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import SettingsVoiceIcon from '@mui/icons-material/SettingsVoice';
import keywordsData from './keywords.json';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { styled } from '@mui/material/styles';
import AbcIcon from '@mui/icons-material/Abc';
import WallpaperIcon from '@mui/icons-material/Wallpaper';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Radio, { RadioProps } from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import EditNoteIcon from '@mui/icons-material/EditNote';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import CanvasDraw from 'react-canvas-draw';
import Draggable from 'react-draggable'; // Import Draggable for text dragging

const CustumVideoEditer = () => {
    const canvasRef = useRef(null);
    const fileInputRef = useRef(null);
    const videofileInputRef = useRef(null);
    const imagefileInputRef = useRef(null);
    const containerRef = useRef(null);
    const navigate = useNavigate(); // Initialize useNavigate
    const { showAlert } = useSweetAlert();
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorEl_sec, setanchorEl_sec] = useState(null);
    const { theme } = useTheme();
    const [showHowToEditerDialog, setShowHowToEditerDialog] = useState(false);
    const isLight = theme === 'light';
    const cnavasBackgroundColor = isLight ? '#eaeaea' : '#0f0b0b';
    const videoListBackgroundColor = isLight ? '#f1f1f1' : '#141010';
    const { showLoading, hideLoading } = useLoading();
    const { language } = useLanguage();
    const [loadingViewBox, setLoadingViewBox] = useState(false);
    const [openStickerModle, setOpenStickerModle] = useState(false);
    const [openTextModle, setOpenTextModle] = useState(false);
    const [videoDimensions, setVideoDimensions] = useState({ width: 0, height: 0 });
    const [userText, setUserText] = useState("");
    const [textPosition, setTextPosition] = useState({ x: -70, y: -235 });
    const [selectedProjectID, setSelectedProjectID] = useState(0);
    const [emptyVideosListCount, setEmptyVideosListCount] = useState(0);
    const [emptyVideosListArray, setEmptyVideosListArray] = useState([]);

    const [loadingAddEditBox, setLoadingAddEditBox] = useState(false);
    const [loadingDeleteEditerBox, setLoadingDeleteEditerBox] = useState(false);
    const [openDeleteEditerBox, setOpenDeleteEditerBox] = useState(false);
    const [openAddEditerModal, setOpenAddEditerModal] = useState(false);
    const [openBackgroundModle, setOpenBackgroundModle] = useState(false);
    const [projectName, setProjectName] = useState('');


    const [resultViewBox, setResultViewBox] = useState('');
    const [userAudioURL, setUserAudioURL] = useState('');
    const [editerBoxData, setEditerBoxData] = useState([]);
    const [openViewModal, setOpenViewModal] = useState(false);
    const [removeBackGround, setRemoveBackGround] = useState("False");
    const [backgroundUrl, setBackgroundUrl] = useState("");
    const [openLoginDialog, setOpenLoginDialog] = useState(false);
    const [openDeleteVideoBox, setOpenDeleteVideoBox] = useState(false);
    const [openProjectListDialog, setOpenProjectListDialog] = useState(false);
    const [loadingEditerBox, setLoadingEditerBox] = useState(false);
    const [loadingEditerAudioBox, setLoadingEditerAudioBox] = useState(false);
    const [loadingDeleteVideoBox, setLoadingDeleteVideoBox] = useState(false);
    const [isPlaySound, setIsPlaySound] = useState(false);
    const [audioRef, setAudioRef] = useState(null);

    const [loadingShare, setLoadingShare] = useState(false);
    const [openSoundModle, setOpenSoundModle] = useState(false);

    const [videos, setVideos] = useState([]);
    const [selectedVideo, setSelectedVideo] = useState(null);
    const [selectedVideoID, setSelectedVideoID] = useState(0);
    const [selectedTime, setSelectedTime] = useState(0);
    const [selectedFrame, setSelectedFrame] = useState(null);
    const [genProcessType, setGenProcessType] = useState('');
    const [cutRanges, setCutRanges] = useState({});
    const [settingStart, setSettingStart] = useState(true); // To track start or end time
    //const [IsLogin, setIsLogin] = useState(false);
    const [showHowToStickerDialog, setShowHowToStickerDialog] = useState(false);
    const [aboutBackDialog, setAboutBackDialog] = useState(false);
    const storedValue = localStorage.getItem('isMobile');
    const isMobileBoolean = storedValue === 'true'; // Convert the string back to a boolean
    const [IsLogin, setIsLogin] = useState(() => {
        // Attempt to load the user from localStorage
        const checkIsLogin = localStorage.getItem('isLogin');
        if (checkIsLogin === "true") {
          // Optionally decode token to get user info
          return true;
        }
        return false;
      });


        // Function to handle the drag of text
  const handleDrag = (e, data) => {
    setTextPosition({ x: data.x, y: data.y });
  };

  // Function to handle the input text change
  const handleTextChange = (e) => {
    setUserText(e.target.value);
  };
  const buttonBackgroundColor = theme === 'light' ? '#dfc7b2' : '#4d4844'
  const boxBackgroundColor = theme === 'light' ? '#e3dfde' : '#080406'
  const textColor = theme === 'light' ? '#870749' : '#fff'
  const scrollColor = theme === 'light' ? '#f0f0f0' : '#0e0000'

      const generateFileName = () => {
        const randomNumber = Math.floor(100000 + Math.random() * 900000); // Generates a 6-digit random number
        return `samrt-loader.com_${randomNumber}.mp4`;
      };

      const shareVideo = async (videoUrl) => {
        setLoadingShare(true);
        try {
          const response = await fetch(videoUrl);
          const blob = await response.blob();
          const file = new File([blob], generateFileName(), { type: 'video/mp4' });
    
          if (navigator.share) {
            await navigator.share({
              files: [file],
              title: 'Check out this video',
              text: 'Here is a video I downloaded!',
            });
          } else {
            console.error('Sharing not supported on this browser');
          }
        } catch (error) {
          console.error('Error sharing video:', error);
        }
        setLoadingShare(false);
      };

      const handleDeletEditerBox = async(projectID) => {
        try{
            setLoadingDeleteEditerBox(true);
            var done = false;
            const formData = new FormData();
            formData.append('key_m[]', projectID);
            const response = await fetch(`https://samrt-loader.com/kydwon/api/delete/projects_video_editer`, {
              method: 'POST',
              headers: {
                'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
              },
              body: formData,
            });
            const data = await response.json();
            if(data.success){
                done = true;
            } else {
                showAlert({
                    text: language === 'ar' ? 
                      'حدث خطأ أثناء الحذف الرجاء المحاولة لاحقا' : 
                      'An error occurred while deleting, Please try login again',
                    icon: 'error',
                    position: language === 'ar' ? 'top-end' : 'top-start',
                  });
            }
        } catch(error) {
            showAlert({
                text: language === 'ar' ? 
                  'حدث خطأ أثناء الحذف الرجاء المحاولة لاحقا' : 
                  'An error occurred while deleting, Please try login again',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start',
              });
        } finally {
            setLoadingDeleteEditerBox(false);
            handleCloseDeleteEditerBox();
            if(done){
                showAlert({
                    text: language === 'ar' ? 
                      'تمت الحذف بنجاح' : 
                      'Deleting successful',
                    icon: 'success',
                    position: language === 'ar' ? 'top-end' : 'top-start',
                  });
                  handleOpenEditerBox();
            }
        }
    };

  const handleCloseDeleteEditerBox = () => {
      setOpenDeleteEditerBox(false);
  };

  const handleCloseTextModel = () => {
    setOpenTextModle(false);
  };

  const handleOpenBackgroundModel = () => {
    setOpenBackgroundModle(true);
  };

  const handleOpenTextModel = () => {
    setOpenTextModle(true);
  };

  const handleGenerateText = () => {
    //setOpenTextModle(false);
  };

  const handleCloseBackgroundModel = () => {
    setOpenBackgroundModle(false);
  };

  const handleOpenDeleteEditerBox = () => {
      setOpenDeleteEditerBox(true);
  };

  const handleOpenAddNewEditerBox = () => {
      setOpenAddEditerModal(true);
  };

  const handleDelete = async(projectID) => {
      setSelectedProjectID(projectID);
      handleOpenDeleteEditerBox();
  };

      const handleChangeBackgroundType = (event) => {
        setRemoveBackGround(event.target.value);
      };
    

      const handleOpenHowToStickerDialog = () => {
        setShowHowToStickerDialog(true);
      }

      const handelOpenAboutBackgroundModel = () => {
        setAboutBackDialog(true);
      }

      const handelCloseAboutBackgroundModel = () => {
        setAboutBackDialog(false);
      }

      const handleCloseStickerModel = () => {
        setOpenStickerModle(false);
      }

      const handleMenuClose = () => {
        setAnchorEl(null);
      };  

      const handleSecMenuClose = () => {
        setanchorEl_sec(null);
      };  
  
      const handleCloseHowToStickerDialog = () => {
        setShowHowToStickerDialog(false);
      }

      const handleCloseHowToEditerDialog = () => {
        setShowHowToEditerDialog(false);
      };

      const handleOpenHowToEditerDialog = () => {
        setShowHowToEditerDialog(true);
      };

      const handleProjectNameChange = (e) => {
        setProjectName(e.target.value);
      };

      const handleCloseAddEditerModal = () => {
        setOpenAddEditerModal(false);
      };

      const [isProjectId, setIsProjectId] = useState(() => {
        // Attempt to load the user from localStorage
        const projectID = localStorage.getItem('project_id');
        if (projectID !== "0") {
          // Optionally decode token to get user info
          return true;
        }
        return false;
      });

      const handleFileChange = async(event) => {
        const file = event.target.files[0];
        if (file) {
          // Call the onUpload function passed as a prop to handle the file upload
          await handleUpload(file);
        }
      };

      const handleImageFileChange = async(event) => {
        const file = event.target.files[0];
        if (file) {
          // Call the onUpload function passed as a prop to handle the file upload
          await handelAddImageFromDevice(file);
        }
      };
      
      const handelAddImageFromDevice = async(file) => {
        handleMenuClose();
        showLoading();
        const formData = new FormData();
        formData.append('file', file);
    
        try {
          const response = await fetch('https://samrt-loader.com/kydwon/api/upload', {
            method: 'POST',
            headers: {
              'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
            },
            body: formData,
          });
    
          if (response.ok) {
            const data = await response.json();
           // alert('Image uploaded successfully!');
            console.log('Uploaded data:', data);
            if (data?.success && data.files?.file?.success) {
              try {
                const fileToken = data.filetoken;
                const formData = new FormData();
                formData.append('process_orgenal_video_file', "0");
                formData.append('process_face_swap_image_file', fileToken);
                formData.append('process_orgenal_audio_file', "0");
                const response2 = await fetch(`https://samrt-loader.com/kydwon/api/edit/process_video_editer/${selectedVideoID}`, {
                  method: 'POST',
                  headers: {
                    'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
                  },
                  body: formData,
                });
                const data2 = await response2.json();
                if(data2.success) {
                  if(data2.process_video_editer.process_face_swap_image_file.url) {
                    setTimeout(() => {
                       setBackgroundUrl(data2.process_video_editer.process_face_swap_image_file.url)
                       hideLoading();
                    }, 3000); // Check again after 3 seconds
                  } else {
                    hideLoading();
                    showAlert({
                      text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, قد يكون ملف غير صالح أو حجم كبير بالرجاء المحاولة في ملف اخر.' : 'Error uploading audio,Your file maybe incruptud or big size Please try another file.',
                      icon: 'error',
                      position: language === 'ar' ? 'top-end' : 'top-start'
                    });
                  }
                }
              } catch (error) {
                console.error('Error uploading image:', error);
                hideLoading();
                showAlert({
                  text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, قد يكون ملف غير صالح أو حجم كبير بالرجاء المحاولة في ملف اخر.' : 'Error uploading audio,Your file maybe incruptud or big size Please try another file.',
                  icon: 'error',
                  position: language === 'ar' ? 'top-end' : 'top-start'
                });
              }
            } else {
              hideLoading();
              showAlert({
                text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, قد يكون ملف غير صالح أو حجم كبير بالرجاء المحاولة في ملف اخر.' : 'Error uploading audio,Your file maybe incruptud or big size Please try another file.',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start'
              });
            }
          } else {
            hideLoading();
            showAlert({
              text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, الرجاء المحاولة لاحقا.' : 'Error uploading audio, Please try again later.',
              icon: 'error',
              position: language === 'ar' ? 'top-end' : 'top-start'
            });
          }
        } catch (error) {
          console.error('Error uploading image:', error);
          hideLoading();
          showAlert({
            text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, الرجاء المحاولة لاحقا.' : 'Error uploading audio, Please try again later.',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
        }
      }

      const handleVideoFileChange = async(event) => {
        const file = event.target.files[0];
        if (file) {
          // Call the onUpload function passed as a prop to handle the file upload
          await handelAddVideoFromDevice(file);
        }
      };

      const handleUpload = async (file) => {
        showLoading();
        const formData = new FormData();
        formData.append('file', file);
    
        try {
          const response = await fetch('https://samrt-loader.com/kydwon/api/upload', {
            method: 'POST',
            headers: {
              'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
            },
            body: formData,
          });
    
          if (response.ok) {
            const data = await response.json();
           // alert('Image uploaded successfully!');
            console.log('Uploaded data:', data);
            if(data.success){
              try {
                const fileToken = data.filetoken;
                const formData = new FormData();
                formData.append('process_orgenal_audio_file', fileToken);
                const response2 = await fetch(`https://samrt-loader.com/kydwon/api/edit/process_video_editer/${selectedVideoID}`, {
                  method: 'POST',
                  headers: {
                    'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
                  },
                  body: formData,
                });
                const data2 = await response2.json();
                if(data2.success) {
                  if(data2.process_video_editer.process_orgenal_audio_file.name) {
                    setTimeout(() => {
                      const mp3Url = `https://samrt-loader.com/kydwon/files/${data2.process_video_editer.process_orgenal_audio_file.name}`
                      setUserAudioURL(mp3Url);
                      hideLoading();
                      showAlert({
                        text: language === 'ar' ? 'تم إضاقة الصوت بنجاح' : 'Audio added success',
                        icon: 'success',
                        position: language === 'ar' ? 'top-end' : 'top-start'
                      });
                    }, 3000); // Check again after 3 seconds
                  } else {
                    hideLoading();
                    showAlert({
                      text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, قد يكون ملف غير صالح أو حجم كبير بالرجاء المحاولة في ملف اخر.' : 'Error uploading audio,Your file maybe incruptud or big size Please try another file.',
                      icon: 'error',
                      position: language === 'ar' ? 'top-end' : 'top-start'
                    });
                  }
                }
              } catch (error) {
                console.error('Error uploading image:', error);
                hideLoading();
                showAlert({
                  text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, قد يكون ملف غير صالح أو حجم كبير بالرجاء المحاولة في ملف اخر.' : 'Error uploading audio,Your file maybe incruptud or big size Please try another file.',
                  icon: 'error',
                  position: language === 'ar' ? 'top-end' : 'top-start'
                });
              }
            }
          } else {
            hideLoading();
            showAlert({
              text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, الرجاء المحاولة لاحقا.' : 'Error uploading audio, Please try again later.',
              icon: 'error',
              position: language === 'ar' ? 'top-end' : 'top-start'
            });
          }
        } catch (error) {
          console.error('Error uploading image:', error);
          hideLoading();
          showAlert({
            text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, الرجاء المحاولة لاحقا.' : 'Error uploading audio, Please try again later.',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
        }
      };

    const handleOpenEditerBox = async() => {
        try{
            setOpenProjectListDialog(true);
            setLoadingEditerBox(true);
            const response = await fetch(`https://samrt-loader.com/kydwon/api/list/projects_video_editer`, {
              method: 'GET',
              headers: {
                'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
              }
            });
            const data = await response.json();
            if(data.success){
                setEditerBoxData(data.projects_video_editer);
            } else {
                handleCloseProjectList();
                showAlert({
                    text: language === 'ar' ? 
                      'حدث خطأ أثناء تحميل معلومات الحساب, الرجاء تسجيل الدخول' : 
                      'An error occurred while getting your account data, Please try login again',
                    icon: 'error',
                    position: language === 'ar' ? 'top-end' : 'top-start',
                  });
                  localStorage.setItem('authToken', null);
                  localStorage.setItem('isLogin', 'false');
                  localStorage.setItem('userid', null);
                  localStorage.setItem('username', null);
                  localStorage.setItem('userlevelid', null);  
            }
        } catch(error) {
            handleCloseProjectList();
            showAlert({
                text: language === 'ar' ? 
                  'حدث خطأ أثناء تحميل معلومات الحساب, الرجاء تسجيل الدخول' : 
                  'An error occurred while getting your account data, Please try login again',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start',
              });
              localStorage.setItem('authToken', null);
              localStorage.setItem('isLogin', 'false');
              localStorage.setItem('userid', null);
              localStorage.setItem('username', null);
              localStorage.setItem('userlevelid', null);  
        } finally {
            setLoadingEditerBox(false);
        }
    };

    const handleChoose = async(projectID) => {
        localStorage.setItem('project_id',projectID);
        setSelectedFrame(null);
        setVideos([]);
        setCutRanges({});
        setSelectedVideoID(0);
        setSelectedVideo(null);
        setSelectedTime(0);
        setIsProjectId(true);
        fetchVideos();   
    }

    const handleMenuClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleSecMenuClick = (event) => {
      setanchorEl_sec(event.currentTarget);
    };

    const handleCloseDeleteVideoBox = () => {
      setOpenDeleteVideoBox(false);
    };

    const handelDeleteVideoList = async() => {
      setOpenDeleteVideoBox(true);
    };

    const handleAddNewEditerBox = async() => {
      try{
          var done = false;
          setLoadingAddEditBox(true);
          const formData = new FormData();
          formData.append('project_by_user_id', localStorage.getItem('userid'));
          formData.append('projject_name', projectName);
          formData.append('project_start_date', "01-01-2024");
          const response = await fetch(`https://samrt-loader.com/kydwon/api/add/projects_video_editer`, {
            method: 'POST',
            headers: {
              'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
            },
            body: formData,
          });
          const data = await response.json();
          if(data.success){
              done = true;
          } else {
              showAlert({
                  text: language === 'ar' ? 
                    'حدث خطأ أثناء الإضافة الرجاء المحاولة لاحقا' : 
                    'An error occurred while adding, Please try login again',
                  icon: 'error',
                  position: language === 'ar' ? 'top-end' : 'top-start',
                });
          }
      } catch(error) {
          showAlert({
              text: language === 'ar' ? 
                'حدث خطأ أثناء الإضافة الرجاء المحاولة لاحقا' : 
                'An error occurred while adding, Please try login again',
              icon: 'error',
              position: language === 'ar' ? 'top-end' : 'top-start',
            });
      } finally {
          setLoadingAddEditBox(false);
          handleCloseAddEditerModal();
          if(done){
              showAlert({
                  text: language === 'ar' ? 
                    'تمت الإضافة بنجاح' : 
                    'Adding successful',
                  icon: 'success',
                  position: language === 'ar' ? 'top-end' : 'top-start',
                });
                handleOpenEditerBox();
          }
      }
    };

    const fetchVideos = async () => {
        try {
            const token = localStorage.getItem('authToken');        
            const response = await axios.get(
                `https://samrt-loader.com/kydwon/api/list/process_video_editer?showmaster=projects_video_editer&fk_project_id=${localStorage.getItem('project_id')}&order=process_id&ordertype=ASC`,
                {
                    headers: {
                        'X-Authorization': `Bearer ${token}` // Add JWT token to the request headers
                    }
                }
            );
            if (response.data.success) {
                const videoData = response.data.process_video_editer;
                const totalEmptyVideos = 4 - response.data.totalRecordCount;
                const newItemsArray = Array.from({ length: totalEmptyVideos }, (_, index) => index + 1);
                setEmptyVideosListArray(newItemsArray);
                setEmptyVideosListCount(totalEmptyVideos);
                setVideos(videoData);
            }
        } catch (error) {
            console.error('Error fetching videos:', error);
        } finally {
            hideLoading();
            handleCloseProjectList();
        }
    };

    const handelIsPlay = (isPlaying,audioRef) => {
          setIsPlaySound(isPlaying);
          setAudioRef(audioRef);
    }
    
    const processAddedVideo = async(videoName,projectID,prossessID) => {
      try {
        var done = false;
        var statusLimit = false;
        showLoading();
        const formData = new FormData();
        formData.append('video_url', `https://samrt-loader.com/kydwon/files/${videoName}`);
        formData.append('src_url', "https://samrt-loader.com");
        formData.append('project_id', projectID);
        formData.append('prossess_id', prossessID);
        formData.append('editer_type', "addNew");

        const response = await fetch(`https://samrt-loader.com/kydwon/api/addtoedit_prossess`, {
            method: 'POST',
            headers: {
                'X-Authorization': `Bearer ${localStorage.getItem('authToken')}`
            },
            body: formData,
        });

        if (response.status === 204) {
            // Handle the 204 status code
            done = false;
            statusLimit = true;
        } else {
            // Handle other response statuses
            const data = await response.json();
            if (data.success) {
                done = true;
            } else {
                // Handle the case where `success` is false
                showAlert({
                    text: language === 'ar' ? 
                        'حدث خطأ أثناء الإضافة الرجاء المحاولة فيديو اخر لايزيد عن 1 دقيقة' : 
                        'An error occurred while adding, Please try login again',
                    icon: 'error',
                    position: language === 'ar' ? 'top-end' : 'top-start',
                });
            }
        }
    } catch (error) {
        showAlert({
            text: language === 'ar' ? 
                'حدث خطأ أثناء الإضافة الرجاء المحاولة فيديو اخر لايزيد عن 1 دقيقة' : 
                'An error occurred while adding, Please try login again',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start',
        });
    } finally {
        hideLoading();
        if (done) {
          fetchVideos();
          showAlert({
            text: language === 'ar' ? 'تم إضاقة الفيديو بنجاح' : 'Video added success',
            icon: 'success',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
        } else {
            if(statusLimit){
                showAlert({
                    text: language === 'ar' ? 
                        'لقد وصلت للحد الأقصى للفيديوهات لهذه القائمة' : 
                        'You reached the video limit to this list',
                    icon: 'error',
                    position: language === 'ar' ? 'top-end' : 'top-start',
                });
            } else {
                showAlert({
                    text: language === 'ar' ? 
                        'حدث خطأ أثناء الإضافة الرجاء المحاولة فيديو اخر لايزيد عن 1 دقيقة' : 
                        'An error occurred while adding, Please try login again',
                    icon: 'error',
                    position: language === 'ar' ? 'top-end' : 'top-start',
                });
            }
        }
    }
    }

    const handelAddVideoFromUrl = () => {
      handleMenuClose();
      navigate(`../`); // Navigate to the ViewRest component
    }

    const BpIcon = styled('span')(({ theme }) => ({
      borderRadius: '50%',
      width: 16,
      height: 16,
      boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '.Mui-focusVisible &': {
        outline: '2px auto rgba(19,124,189,.6)',
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
        ...theme.applyStyles('dark', {
          backgroundColor: '#30404d',
        }),
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
        ...theme.applyStyles('dark', {
          background: 'rgba(57,75,89,.5)',
        }),
      },
      ...theme.applyStyles('dark', {
        boxShadow: '0 0 0 1px rgb(16 22 26 / 40%)',
        backgroundColor: '#394b59',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))',
      }),
    }));
    
    const BpCheckedIcon = styled(BpIcon)({
      backgroundColor: '#137cbd',
      backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&::before': {
        display: 'block',
        width: 16,
        height: 16,
        backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: '#106ba3',
      },
    });

    // Inspired by blueprintjs
function BpRadio(props) {
  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      {...props}
    />
  );
}

    const handelAddVideoFromDevice = async(file) => {
      handleMenuClose();
      showLoading();
      const formData = new FormData();
      formData.append('file', file);
  
      try {
        const response = await fetch('https://samrt-loader.com/kydwon/api/upload', {
          method: 'POST',
          headers: {
            'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
          },
          body: formData,
        });
  
        if (response.ok) {
          const data = await response.json();
         // alert('Image uploaded successfully!');
          console.log('Uploaded data:', data);
          if (data?.success && data.files?.file?.success) {
            try {
              const fileToken = data.filetoken;
              const formData = new FormData();
              const projectID = localStorage.getItem('project_id');
              formData.append('process_by_user_id', localStorage.getItem('userid'));
              formData.append('by_project_id', projectID);
              formData.append('process_orginal_video_url', "https://samrt-loader.com");
              formData.append('process_src_url', "https://samrt-loader.com");
              formData.append('process_orgenal_video_file', fileToken);
              formData.append('process_orgenal_audio_file', "");
              formData.append('process_face_swap_image_file', "");
              formData.append('process_video_frames', "addNew");
              formData.append('process_video_thumbnail_url', "addNew");
              const response2 = await fetch(`https://samrt-loader.com/kydwon/api/add/process_video_editer?showmaster=projects_video_editer&fk_project_id=${projectID}`, {
                method: 'POST',
                headers: {
                  'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
                },
                body: formData,
              });
              const data2 = await response2.json();
              if(data2.success) {
                if(data2.process_video_editer.process_orgenal_video_file.name) {
                  setTimeout(async () => {
                    await processAddedVideo(data2.process_video_editer.process_orgenal_video_file.name,projectID,data2.process_video_editer.process_id)
                  }, 3000); // Check again after 3 seconds
                } else {
                  hideLoading();
                  showAlert({
                    text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, قد يكون ملف غير صالح أو حجم كبير بالرجاء المحاولة في ملف اخر.' : 'Error uploading audio,Your file maybe incruptud or big size Please try another file.',
                    icon: 'error',
                    position: language === 'ar' ? 'top-end' : 'top-start'
                  });
                }
              }
            } catch (error) {
              console.error('Error uploading image:', error);
              hideLoading();
              showAlert({
                text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, قد يكون ملف غير صالح أو حجم كبير بالرجاء المحاولة في ملف اخر.' : 'Error uploading audio,Your file maybe incruptud or big size Please try another file.',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start'
              });
            }
          } else {
            hideLoading();
            showAlert({
              text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, قد يكون ملف غير صالح أو حجم كبير بالرجاء المحاولة في ملف اخر.' : 'Error uploading audio,Your file maybe incruptud or big size Please try another file.',
              icon: 'error',
              position: language === 'ar' ? 'top-end' : 'top-start'
            });
          }
        } else {
          hideLoading();
          showAlert({
            text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, الرجاء المحاولة لاحقا.' : 'Error uploading audio, Please try again later.',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        hideLoading();
        showAlert({
          text: language === 'ar' ? 'حدث خطأ أثناء الإضافة, الرجاء المحاولة لاحقا.' : 'Error uploading audio, Please try again later.',
          icon: 'error',
          position: language === 'ar' ? 'top-end' : 'top-start'
        });
      }
    }

    const handleDeletImageBox = async () => {
      showLoading();
      try {
        const formData = new FormData();
        formData.append('process_id', selectedVideoID);
        const response2 = await fetch(`https://samrt-loader.com/kydwon/api/deleteimage`, {
          method: 'POST',
          headers: {
            'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
          },
          body: formData,
        });
        const data2 = await response2.json();
        console.log('Uploaded data:', data2);
        if(data2.success) {
          setBackgroundUrl('');
          showAlert({
            text: language === 'ar' ? 'تم حذف الصورة بنجاح' : 'Background delete success',
            icon: 'success',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
        }
      } catch (error) {
        console.error('Error uploading image:', error);
        showAlert({
          text: language === 'ar' ? 'حدث خطأ أثناء الحذف, الرجاء المحاولة لاحقا.' : 'Error deleting audio, Please try again later.',
          icon: 'error',
          position: language === 'ar' ? 'top-end' : 'top-start'
        });
      } finally {
        hideLoading();
      }
    }

    const handleDeletAudioBox = async () => {
      if (isPlaySound) {
        audioRef.current.pause();
      }
      setLoadingEditerAudioBox(true);
      try {
        const formData = new FormData();
        formData.append('process_id', selectedVideoID);
        const response2 = await fetch(`https://samrt-loader.com/kydwon/api/deletesound`, {
          method: 'POST',
          headers: {
            'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
          },
          body: formData,
        });
        const data2 = await response2.json();
        console.log('Uploaded data:', data2);
        if(data2.success) {
          setUserAudioURL('');
          showAlert({
            text: language === 'ar' ? 'تم حذف الصوت بنجاح' : 'Audio delete success',
            icon: 'success',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
        }
      } catch (error) {
        console.error('Error uploading sound:', error);
        showAlert({
          text: language === 'ar' ? 'حدث خطأ أثناء الحذف, الرجاء المحاولة لاحقا.' : 'Error deleting audio, Please try again later.',
          icon: 'error',
          position: language === 'ar' ? 'top-end' : 'top-start'
        });
      } finally {
        setLoadingEditerAudioBox(false);
      }
    }

    const handleDeletVideoBox = async () => {
      try{
        handleCloseDeleteVideoBox();
        showLoading();
        var done = false;
        const formData = new FormData();
        formData.append('key_m[]', selectedVideoID);
        const response = await fetch(`https://samrt-loader.com/kydwon/api/delete/process_video_editer`, {
          method: 'POST',
          headers: {
            'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
          },
          body: formData,
        });
        const data = await response.json();
        if(data.success){
            done = true;
        } else {
            showAlert({
                text: language === 'ar' ? 
                  'حدث خطأ أثناء الحذف الرجاء المحاولة لاحقا' : 
                  'An error occurred while deleting, Please try login again',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start',
              });
        }
    } catch(error) {
        showAlert({
            text: language === 'ar' ? 
              'حدث خطأ أثناء الحذف الرجاء المحاولة لاحقا' : 
              'An error occurred while deleting, Please try login again',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start',
          });
    } finally {
        hideLoading();
        if(done){
            showAlert({
                text: language === 'ar' ? 
                  'تمت الحذف بنجاح' : 
                  'Deleting successful',
                icon: 'success',
                position: language === 'ar' ? 'top-end' : 'top-start',
              });
              setSelectedVideo(null);
              setSelectedFrame(null);
              fetchVideos();
        }
    }
    }

    useEffect(() => {
        if(IsLogin){
            if(isProjectId){
                showLoading();
                fetchVideos();
            } else {
                handleOpenEditerBox();
            }
        }else {
            setOpenLoginDialog(true);
        }
        window.scrollTo(0, 0);
    }, []);

    const handleClose = () => {
        setOpenLoginDialog(false);
    }

    const handleCloseProjectList = () => {
        setOpenProjectListDialog(false);
    }

    const handleCloseSoundModel = () => {
      setOpenSoundModle(false);
    }

    const handleOpenSoundModel = () => {
      setOpenSoundModle(true);
    }

    const handleSelectVideo = async(video) => {
        setSelectedVideo(video);
        setSelectedVideoID(video.process_id);
        setSelectedFrame(null);
        setLoadingEditerAudioBox(true);
        try{
          const response = await fetch(`https://samrt-loader.com/kydwon/api/view/process_video_editer/${video.process_id}`, {
            method: 'GET',
            headers: {
              'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
            }
          });
          const data = await response.json();
          if(data.success) {
            if(data.process_video_editer.process_orgenal_audio_file !== null) {
              const mp3Url = `https://samrt-loader.com/kydwon/files/${data.process_video_editer.process_orgenal_audio_file.name}`
              setUserAudioURL(mp3Url);
              const videoHeight = data.process_video_editer.process_video_height;
              const videoWidth = data.process_video_editer.process_video_width;
              setVideoDimensions({ height:videoHeight,width:videoWidth });
            } else {
              setUserAudioURL('');
            }
            if(data.process_video_editer.process_face_swap_image_file !== null) {
              setBackgroundUrl(data.process_video_editer.process_face_swap_image_file.url);
            } else {
              setBackgroundUrl('');
            }
          } else {
            setUserAudioURL('');
            setBackgroundUrl('');
          }
        } catch(error){
          setUserAudioURL('');
        } finally {
          setLoadingEditerAudioBox(false);
        }
    };

    const handleSelectFrame = (frame, index) => {
        setSelectedFrame(frame);
        handleSelectTime(index); // Pass the index to calculate time
    };

    const handleEdit = async(projectID) => {
        
    };

    const handleGenerateAddSoundProcess = () => {
      fileInputRef.current.click();
    };

    const handleGenerateAddBackGroundProcess = () => {
      imagefileInputRef.current.click();
    };

    const handleGenerateAddVideoProcess = () => {
      videofileInputRef.current.click();
    };

    const handleCutRangeChange = (videoId, field, value) => {
      setCutRanges((prev) => {
          const currentRange = prev[videoId] || {};
  
          if (field === 'startTime') {
              // Ensure startTime is less than endTime (if already set)
              if (currentRange.endTime && value >= currentRange.endTime) {
                  showAlert({
                      text: language === 'ar' ? 
                        'يجب أن يكون وقت البداية أقل من وقت النهاية' : 
                        'Start time must be less than the end time',
                      icon: 'error',
                      position: language === 'ar' ? 'top-end' : 'top-start',
                    });
                  return prev; // Do not update if invalid
              }
          } else if (field === 'endTime') {
              // Ensure endTime is greater than startTime (if already set)
              if (currentRange.startTime && value <= currentRange.startTime) {
                  showAlert({
                      text: language === 'ar' ? 
                        'يجب أن يكون وقت النهاية أكبر من وقت البداية' : 
                        'End time must be greater than the start time',
                      icon: 'error',
                      position: language === 'ar' ? 'top-end' : 'top-start',
                    });
                  return prev; // Do not update if invalid
              }
          }
  
          // Update the cut range if validation passes
          return {
              ...prev,
              [videoId]: {
                  ...currentRange,
                  [field]: value
              }
          };
      });
  };

    const handleGenerateProcess = async (processType) => {
      try {
          if (processType === 'cut_video') {
            if(isAllFramesSelected()){
              if(chackAllDeuration()) {
              const selectedCutRanges = Object.entries(cutRanges).map(([videoId, cutRange]) => ({
                videoId,
                startTime: cutRange.startTime,
                endTime: cutRange.endTime,
            }));
            showLoading();
            const response = await axios.post('https://webapppros.com/process_video_editer', {
              videos: selectedCutRanges,
            });
            if (response.data.success) {
                checkStatus(response.data.task_id,processType);
            } else {
                showAlert({
                  text: language === 'ar' ? 'فشل الإنشاء الرجاء المحاولة لاحقا' : 'Error starting the processing, Please try again later !',
                  icon: 'error',
                  position: language === 'ar' ? 'top-end' : 'top-start'
                });
            }
          }else {
            showAlert({
              text: language === 'ar' ? 'جميع المقاطع في القائمة يجب أن لاتزيد مدة القص أكثر من 10 ثوان لكل فيديو' : 'All clips in the list must not exceed 10 seconds for each video.!',
              icon: 'error',
              position: language === 'ar' ? 'top-end' : 'top-start'
            });
            return;
          }
          } else {
            showAlert({
              text: language === 'ar' ? 'الرجاء تحديد إطار الوقت لجميع الفيديوهات في القائمة الجانبية أولا !' : 'Please specify the time frame for all videos in the sidebar!',
              icon: 'error',
              position: language === 'ar' ? 'top-end' : 'top-start'
            });
          }
          } else {
            handleCloseStickerModel();
            const video = cutRanges[selectedVideoID];  // Assuming single video for gif conversion
            if(video.startTime > 0 && video.endTime > 0 && video.startTime !== '' && video.startTime !== null && !isNaN(video.startTime) && video.startTime !== undefined && video.endTime !== '' && video.endTime !== null && !isNaN(video.endTime) && video.endTime !== undefined) {
              const viledDuretion = video.endTime - video.startTime;
              if(viledDuretion > 5){
                showAlert({
                  text: language === 'ar' ? 'فشل الإنشاء, يجب أن لايزيد مدة الستيكر عن 5 ثوان' : 'Error starting the processing, sticker duration must not exceed 5 seconds',
                  icon: 'error',
                  position: language === 'ar' ? 'top-end' : 'top-start'
                });
                return;
              }
              showLoading();
              const response = await axios.post('https://webapppros.com/process_video_editer', {
                process_type: 'video_to_gif',
                remove_back_ground: removeBackGround,
                video_id: selectedVideoID,  // Replace with actual video ID
                startTime: video.startTime,  // Start time in seconds
                endTime: video.endTime  // End time in seconds
              });
              if (response.data.success) {
                checkStatus(response.data.task_id,processType);
              } else {
                showAlert({
                  text: language === 'ar' ? 'فشل الإنشاء الرجاء المحاولة لاحقا' : 'Error starting the processing, Please try again later !',
                  icon: 'error',
                  position: language === 'ar' ? 'top-end' : 'top-start'
                });
               }
            } else {
              showAlert({
                text: language === 'ar' ? 'الرجاء تحديد إطار الوقت للفيديو المحدد في القائمة الجانبية أولا !' : 'Please specify the time frame for all videos in the sidebar!',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start'
              });
            }
          }
      } catch (error) {
          console.error('Error sending data to backend:', error);
          showAlert({
            text: language === 'ar' ? 'حدث خطأ الرجاء التأكد من تحديد الوقت لكل فيديو وإعادة المحاولة أو المحاولة لاحقا' : 'An error, Please specify the time frame for all videos in the sidebar or try again later!',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
      } finally {
          hideLoading();
      }
    };

    const chackAllDeuration = () => {
      let allFramesSelected = true;
      videos.forEach((video) => {
        const videoId = video.process_id; // Assuming each video object has an 'id'
        const cutRange = cutRanges[videoId]; // Get the cut range for this video
        let deu = cutRange.endTime - cutRange.startTime ;
        if (deu > 10) {
          allFramesSelected = false;
        }
      });
      return allFramesSelected;
    };

    const isAllFramesSelected = () => {
      let allFramesSelected = true;
    
      videos.forEach((video) => {
        const videoId = video.process_id; // Assuming each video object has an 'id'
        const cutRange = cutRanges[videoId]; // Get the cut range for this video
    
        if (!cutRange || !Object.values(cutRange).every(
          (value) => value !== '' && value !== null && !isNaN(value) && value > 0 && value !== undefined
        )) {
          allFramesSelected = false;
        }

      });
    
      return allFramesSelected;
    };

    const checkStatus = async (taskId,type) => {
        setLoadingViewBox(true);
        setOpenViewModal(true);
        const statusResponse = await fetch(`https://webapppros.com/task_status_editer/${taskId}`);
        const statusData = await statusResponse.json();
    
        if (statusData.status === 'PENDING') {
          setTimeout(() => checkStatus(taskId), 5000); // Check again after 5 seconds
        } else if (statusData.status === 'SUCCESS') {
          const url = statusData.result;
          setResultViewBox(url);
          setGenProcessType(type);
          setLoadingViewBox(false);
          //console.error('success path', url);
        } else {
          console.error('Error processing video:', statusData.status);
          setOpenViewModal(false);
          showAlert({
            text: language === 'ar' ? 'فشل الإنشاء الرجاء المحاولة لاحقا' : 'Error starting the processing, Please try again later !',
            icon: 'error',
            position: language === 'ar' ? 'top-end' : 'top-start'
          });
        }
      };

    const handleSelectTime = (frameIndex) => {
        const currentTime = frameIndex + 1; // Assuming frame 1 corresponds to 1 second, frame 2 to 2 seconds, etc.
        setSelectedTime(currentTime);
        const field = settingStart ? 'startTime' : 'endTime';
        handleCutRangeChange(selectedVideoID, field, currentTime);
        setSettingStart(!settingStart); // Toggle between start and end time
    };

    const handleCloseViewModal = () => {
        setOpenViewModal(false);
    }

    const handelOpenStickerModle = () => {
      setOpenStickerModle(true);
    }

    const SEO = ({ title, description, keywords }) => (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <script type="application/ld+json">
          {`
            {
              "@context": "https://schema.org",
              "@type": "WebSite",
              "name": "${title}",
              "url": "https://samrt-loader.com"
            }
          `}
        </script>
      </Helmet>
    );

    return (
        <div>
        <SEO 
        title={language === 'ar' ? keywordsData.title_editer_ar : keywordsData.title_editer_en}
        description={language === 'ar' ? keywordsData.description_ar : keywordsData.description_en}
        keywords={keywordsData.keywords.join(', ')}
      />
 {IsLogin ? (
            <div className="studio-container">
            <div className="video-list" style={{ backgroundColor: videoListBackgroundColor }}>
                {videos.map((video, index) => (
                    <div key={video.process_id} className="video-thumbnail" onClick={() => handleSelectVideo(video)}>
                        <img src={video.process_video_thumbnail_url} alt={video.process_orgenal_video_file.name} />
                        <p>{`Video - ${index + 1}`}</p>
                    </div>
                ))}
                {emptyVideosListCount > 0 && (
                  emptyVideosListArray.map((index) => (
                    <div key={index} className="video-thumbnail">
                        <VideoCameraFrontIcon style={{width:'100%',height:100}}/>
                        <p>{`epmty Video - ${videos.length + index}`}</p>
                    </div>
                  ))
                )}
            </div>

            <div className="editor-area">
                <div className="action-bar">
                  <Box>
                    <ButtonGroup variant="contained" color="inherit" aria-label="outlined button group">
                            <Button disabled={!selectedVideo || loadingEditerAudioBox} aria-label="video edit options" variant="contained" color="inherit" onClick={handleSecMenuClick}>
                                      {loadingEditerAudioBox ? (
                                <CircularProgress color='error' size={24} />
                            ) : (
                                <EditNoteIcon />
                            )}
                                  </Button>
                                  <Menu                                           
                                    anchorEl={anchorEl_sec}
                                    open={Boolean(anchorEl_sec)}
                                    onClose={handleSecMenuClose}
                                    aria-label='menu'>
                                    <MenuList>
                                        <MenuItem disabled={true} onClick={handleOpenTextModel}>
                                          <ListItemIcon>
                                            <AbcIcon fontSize="small" />
                                          </ListItemIcon>
                                          <ListItemText>{language === 'ar' ? 'إضافة نص' : 'Add text'}</ListItemText>
                                        </MenuItem>
                                        <MenuItem onClick={handleOpenBackgroundModel}>
                                          <ListItemIcon>
                                            <WallpaperIcon fontSize="small" />
                                          </ListItemIcon>
                                          <ListItemText>{language === 'ar' ? 'إضافة خلفية' : 'Add background'}</ListItemText>
                                        </MenuItem>
                                        <MenuItem onClick={handleOpenSoundModel}>
                                          <ListItemIcon>
                                            <SettingsVoiceIcon fontSize="small" />
                                          </ListItemIcon>
                                          <ListItemText>{language === 'ar' ? 'إضافة صوت' : 'Add sound'}</ListItemText>
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem onClick={handelOpenStickerModle}>
                                          <ListItemIcon>
                                            <VideoStableIcon fontSize="small" />
                                          </ListItemIcon>
                                          <ListItemText>{language === 'ar' ? 'إنشاء ستيكر' : 'GIF/Sticker'}</ListItemText>
                                        </MenuItem>
                                        <Divider />
                                        <MenuItem onClick={handelDeleteVideoList}>
                                          <ListItemIcon>
                                            <DeleteForeverIcon fontSize="small" />
                                          </ListItemIcon>
                                          <ListItemText>{language === 'ar' ? 'حذف من القائمة' : 'Delete from list'}</ListItemText>
                                        </MenuItem>
                                    </MenuList>
                                  </Menu>

                        <Button disabled={!isProjectId} aria-label="generate" variant="contained" color="inherit" onClick={handleMenuClick}>
                            <VideoCallIcon />
                        </Button>
                        <Menu
                          anchorEl={anchorEl}
                          open={Boolean(anchorEl)}
                          onClose={handleMenuClose}
                          aria-label='menu'
                        >
                          <MenuItem onClick={handelAddVideoFromUrl}>{language === 'ar' ? 'إضافة فيديو من رابط' : 'Add video from url'}</MenuItem>
                          <MenuItem onClick={handleGenerateAddVideoProcess}>{language === 'ar' ? 'إضافة فيديو من الجهاز' : 'Add video from device'}</MenuItem>
                          <input
                                  type="file"
                                  accept="video/mp4"
                                  ref={videofileInputRef}
                                  style={{ display: 'none' }}
                                  onChange={handleVideoFileChange}/>
                        </Menu>
                        <Button aria-label="choos project" variant="contained" color="info" onClick={handleOpenEditerBox}>
                            <PlaylistPlayIcon />
                        </Button>
                        <Button aria-label="generate" variant="contained" color="success" onClick={() => handleGenerateProcess('cut_video')}>
                            {language === 'ar' ? 'إنشاء' : <PlayArrowIcon />}
                        </Button>
                    </ButtonGroup>
                    </Box>
                </div>
                <Box>
                {selectedVideoID > 0 && (
                        <div className="cut-inputs">
                            <CustomTextField
                                label={language === 'ar' ? 'وقت البداية' : 'Start Time (s)'}
                                variant="outlined"
                                value={cutRanges[selectedVideoID]?.startTime || ''}
                                type="number"
                                name="startTime"
                                onChange={(e) => handleCutRangeChange(selectedVideoID, 'startTime', e.target.value)}
                                margin="normal"
                                inputProps={{ dir: language === 'ar' ? 'rtl' : 'ltr' }}
                            />
                            <CustomTextField
                                label={language === 'ar' ? 'وقت النهاية' : 'End Time (s)'}
                                variant="outlined"
                                value={cutRanges[selectedVideoID]?.endTime || ''}
                                type="number"
                                name="endTime"
                                onChange={(e) => handleCutRangeChange(selectedVideoID, 'endTime', e.target.value)}
                                margin="normal"
                                inputProps={{ dir: language === 'ar' ? 'rtl' : 'ltr' }}
                            />
                        </div>
                    )}
                </Box>

                <Box sx={{
                            overflowX: 'auto',
                            whiteSpace: 'nowrap',
                            padding: '10px',
                            backgroundColor: [boxBackgroundColor],
                            top: -0.5,
                            zIndex: 1,
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Add a subtle shadow
                            '&::-webkit-scrollbar': { // Change scrollbar styles
                              width: '8px',
                              height: '8px',
                              backgroundColor: [scrollColor],
                            },
                            '&::-webkit-scrollbar-thumb': {
                              backgroundColor: '#ccc',
                              borderRadius: '4px',
                            },
                            '&::-webkit-scrollbar-track': {
                              backgroundColor: [scrollColor],
                            },
                        }}
                        width="100%">                   
                         {selectedVideo && selectedVideo.process_video_frames.split(', ').map((frame, index) => (
                        <Box key={index} className="frame" onClick={() => handleSelectFrame(frame,index)} sx={{ display: 'inline-block', marginRight: 4 }}>
                            <img src={frame} alt={`Sec ${index + 1}`} />
                            <p>{`Sec ${index + 1}`}</p>
                        </Box>
                    ))}
                </Box>
                
<div className="canvas-preview" style={{ backgroundColor: cnavasBackgroundColor }}>
  {selectedFrame ? (
    <div ref={containerRef} style={{ position: 'relative', display: 'inline-block' }}>
      {/* Text input for user to enter the text */}
      <div>
        {/* CanvasDraw component */}
        <img src={selectedFrame} alt="Selected Frame" style={{ width: '100%', height: 'auto' }} />

        {/* Draggable text on canvas */}
        {userText && (
          <Draggable
            position={{ x: textPosition.x, y: textPosition.y }}
            onDrag={handleDrag}
            bounds="parent" 
          >
            <div
              style={{
                position: 'absolute',
                cursor: 'move',
                color: 'red', // Customize the text style here
                fontSize: '20px',
                fontWeight: 'bold',
              }}
            >
              {userText}
            </div>
          </Draggable>
        )}
      </div>
    </div>
  ) : (
    <div>
    {emptyVideosListCount === 4 ? (
    <p>{language === 'ar' ? 'قم بإضافة فيديو جديد للقائمة من زر إضافة فيديو في الأعلى' : 'Add a new video to the list by adding a video button at the top.'}&nbsp;<VideoCallIcon /></p>
    )
   : (
    <p>{language === 'ar' ? 'إختر إطار لبدء التحديد' : 'Select a frame to preview'}</p>
   )}
    </div>
  )}
</div>

            </div>
            <Dialog open={openViewModal} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'عرض' : 'View'}</DialogTitle>
        <DialogContent textAlign={'center'}>
        {loadingViewBox ? (
                    <span>
                        <CircularProgress size={24} /> {language === 'ar' ? 'جار الإنشاء (قد يستغرق عدة دقائق) ...' : 'Prossesing (it may take awhile) ...'}
                    </span>
                ) : (
                  <span>
                  {/* Check if the resultViewBox ends with '.mp4' or '.gif' */}
                  {resultViewBox.endsWith('.mp4') ? (
                    <Box sx={{ width: '80vw', maxWidth: '500px', overflow: 'hidden', margin: 'auto' }}>
                      <ReactPlayer url={resultViewBox} controls width="100%" style={{ maxHeight: '380px' }} />
                    </Box>
                  ) : resultViewBox.endsWith('.gif') ? (
                    <Box sx={{ width: '80vw', maxWidth: '500px', overflow: 'hidden', margin: 'auto' }}>
                      <img src={resultViewBox} alt='sticker photo' width="300px" height={'100%'} style={{ maxHeight: '300px' }} />
                    </Box>
                  ) : (
                    <span>
                      {language === 'ar' ? 'الرجاء المحاولة لاحقاً' : 'Please try again later'}
                    </span>
                  )}
                </span>
                )}
        </DialogContent>
        {!loadingViewBox && (
        <DialogActions>
        <Button aria-label={"close"} disabled={loadingViewBox} onClick={handleCloseViewModal} color="secondary">
          {language === 'ar' ? 'إغلاق' : 'Cancel'}
        </Button>

        {isMobileBoolean ? (
          <ButtonGroup variant="contained" color="inherit" aria-label="outlined button group">
              <Button disabled={loadingViewBox} aria-label={"download_edit_video"} color="success" href={resultViewBox} download>
                <FontAwesomeIcon icon="fa-download" />&nbsp;&nbsp;{language === 'ar' ? 'تحميل' : 'Download'}
              </Button> 
              <Button disabled={loadingShare} aria-label={"download_share_edit_video"} color="info" onClick={() => shareVideo(resultViewBox)}>
                    {loadingShare ? (
                      <span>
                        <CircularProgress size={24} />&nbsp;&nbsp;{language === 'ar' ? 'جار التحميل ...' : 'Donloading ...'}
                      </span>
                    ) : (
                      <span>
                        <FontAwesomeIcon icon="fa-share" />&nbsp;&nbsp;{language === 'ar' ? 'تحميل و مشاركة' : 'Download & Share'}
                      </span>
                    )}
              </Button> 
          </ButtonGroup>
        ) : (
          <Button disabled={loadingViewBox} aria-label={"download_edit_video"} color="success" href={resultViewBox} download>
            <FontAwesomeIcon icon="fa-download" />&nbsp;&nbsp;{language === 'ar' ? 'تحميل' : 'Download'}
          </Button> 
        )}
        </DialogActions>
        )}
      </Dialog>

      <Dialog open={openProjectListDialog} onClose={handleCloseProjectList} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'قائمة المحرر' : 'Editer list'}</DialogTitle>
        <DialogContent>
          <DialogContentText textAlign={'center'}>
            {language === 'ar' ? 'إختيار موضوع جديد للمحرر' : 'Choose your projects'}
          </DialogContentText>
          {loadingEditerBox ? (
            <Box width="100%" minWidth={'300px'} my={5} textAlign={'center'}>
                <CircularProgress color='error' size={24} />
            </Box>
    ) : editerBoxData.length > 0 ? (
        <Box sx={{ maxHeight: '350px', overflow: 'auto' }}>
        <List>
          {editerBoxData.map((project) => (
            <React.Fragment key={project.project_id}>
              <ListItem>
              <ListItemButton onClick={() => handleChoose(project.project_id)}>
                  <ListItemText
                    primary={project.projject_name}
                    secondary={language === 'ar' ? `( ${project.project_video_count} / 4 )` : `( ${project.project_video_count} / 4 )`}
                  />
                </ListItemButton>
                <Tooltip title={language === 'ar' ? 'تعديل' : 'Edit'}>
                  <IconButton edge="end" disabled={true} aria-label="edit" onClick={() => handleEdit(project.project_id)}>
                    <EditIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title={language === 'ar' ? 'حذف' : 'Delete'}>
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDelete(project.project_id)}>
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>
        </Box>
      ) : (
        <Typography textAlign={'center'} color="textSecondary">
          {language === 'ar' ? 'لم يتم العثور على مواضيع' : 'No projects found'}
        </Typography>
      )}
        </DialogContent>
        <DialogActions>
          <Button aria-label={"close"} onClick={handleCloseProjectList} color="primary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
          <Button aria-label={"add project"} onClick={handleOpenAddNewEditerBox} color="success">
            {language === 'ar' ? 'إضافة موضوع جديد' : 'Add new project'}
          </Button>
        </DialogActions>
      </Dialog>
        </div>
        ) : (
          <div className="studio-container">
          <Box textAlign={'center'} alignItems={'center'} width={'100%'} my={5} mt={5}>
             <img src="../favpng_video-editing-logo.webp" alt='video editer' width='270px' height="270px"/>
          </Box>
        <Dialog open={openLoginDialog} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogContent textAlign={'center'}>
            <p>{language === 'ar' ? 'الرجاء تسجيل الدخول للبدء' : 'Please login to start'}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
        </DialogActions>
      </Dialog>
      </div>
        )}
        <Box width={'100%'} textAlign={'center'} alignItems={'center'}>
            <Button aria-label='how to use' color='info' onClick={handleOpenHowToEditerDialog}>
                {language === 'ar' ? 'طريقة الإستعمال' : 'How to use'}
            </Button>
        </Box>

      {/* Dialog How to editer */}
      <Dialog open={showHowToEditerDialog} onClose={handleCloseHowToEditerDialog} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle>{language === 'ar' ? 'طريقة الإستعمال' : 'How to use'}</DialogTitle>
        <DialogContent>
        <DialogContentText mt={1} my={2}>
            <p>{language === 'ar' ? 'قم بقص وتجميع الفيديوهات المحملة من مختلف المنصات ودمجها في فيديو واحد يناسب جميع منصات التواصل لمشاركته , جربها لان وإستمتع بإنشاء تجميعة مميزة ومشاركتها مع أصدقائك .' : 'Cut and collect videos downloaded from different platforms and merge them into one video that suits all social media platforms to share. Try it now and enjoy creating a unique collection and sharing it with your friends .'}</p>
            <p>{language === 'ar' ? 'تم إضافة خاصية تحويل الفيديو إلى ستيكر GIF متحرك إلى المحرر.' : 'Adding Video to GIF sticker conversion to the editer.'} <Link onClick={handleOpenHowToStickerDialog} href="#" color="inherit" className="animated-link">{language === 'ar' ? 'طريقة الإستخدام' : 'how to use'}</Link></p>
            <p>{language === 'ar' ? 'ملاحظة : نحن لا نحتفظ بالفيديوهات التي قمت بإنشائها أكثر من 12 ساعة , يتم تجديد قائمتك تلقائيا.' : 'Note: We do not keep your generated videos for more than 12 hours, your list is updated automatically.'}</p>
          </DialogContentText>
          <Box mt={1} my={2}>
          <video controls style={{ width: '100%', maxHeight: '380px' }}>
            <source src={"https://samrt-loader.com/how_to_editer.mp4"} type={"video/mp4"} />
            Your browser does not support the video tag.
          </video>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHowToEditerDialog} color="primary">
            {language === 'ar' ? 'إغلاق' : 'got it !'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog How to sticker */}
      <Dialog open={showHowToStickerDialog} onClose={handleCloseHowToStickerDialog} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle>{language === 'ar' ? 'طريقة إنشاء ستيكر من الفيديو' : 'How to sticker video'}</DialogTitle>
        <DialogContentText mt={1} my={2}>
            <p>{language === 'ar' ? 'ماهو الستيكر ؟' : 'What is sticker video ?'}</p>
            <p>{language === 'ar' ? 'هو ملصق بصيغة صورة GIF يستخدم في رسائل الواتس اب أو السوشال ميديا في الكومنت التي تدعم الصور المتحركة' : 'It is a sticker in GIF format used in WhatsApp messages or social media in the comments that support animated images.'}</p>
        </DialogContentText>
        <DialogContent>
          <Box mt={1} my={2}>
          <video controls style={{ width: '100%', maxHeight: '380px' }}>
            <source src={"https://samrt-loader.com/how_to_sticker.mp4"} type={"video/mp4"} />
            Your browser does not support the video tag.
          </video>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHowToStickerDialog} color="primary">
            {language === 'ar' ? 'حسنا !' : 'got it !'}
          </Button>
        </DialogActions>
      </Dialog>
      
    
      {/* Dialog How to editer */}
      <Dialog open={showHowToEditerDialog} onClose={handleCloseHowToEditerDialog} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle>{language === 'ar' ? 'طريقة الإستعمال' : 'How to use'}</DialogTitle>
        <DialogContent>
        <DialogContentText mt={1} my={2}>
            <p>{language === 'ar' ? 'قم بقص وتجميع الفيديوهات المحملة من مختلف المنصات ودمجها في فيديو واحد يناسب جميع منصات التواصل لمشاركته , جربها لان وإستمتع بإنشاء تجميعة مميزة ومشاركتها مع أصدقائك .' : 'Cut and collect videos downloaded from different platforms and merge them into one video that suits all social media platforms to share. Try it now and enjoy creating a unique collection and sharing it with your friends .'}</p>
            <p>{language === 'ar' ? 'تم إضافة خاصية تحويل الفيديو إلى ستيكر GIF متحرك إلى المحرر.' : 'Adding Video to GIF sticker conversion to the editer.'} <Link onClick={handleOpenHowToStickerDialog} href="#" color="inherit" className="animated-link">{language === 'ar' ? 'طريقة الإستخدام' : 'how to use'}</Link></p>
            <p>{language === 'ar' ? 'ملاحظة : نحن لا نحتفظ بالفيديوهات التي قمت بإنشائها أكثر من 12 ساعة , يتم تجديد قائمتك تلقائيا.' : 'Note: We do not keep your generated videos for more than 12 hours, your list is updated automatically.'}</p>
          </DialogContentText>
          <Box mt={1} my={2}>
          <video controls style={{ width: '100%', maxHeight: '380px' }}>
            <source src={"https://samrt-loader.com/how_to_editer.mp4"} type={"video/mp4"} />
            Your browser does not support the video tag.
          </video>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHowToEditerDialog} color="primary">
            {language === 'ar' ? 'إغلاق' : 'got it !'}
          </Button>
        </DialogActions>
      </Dialog>

      {/* Dialog How to Background */}
      <Dialog open={aboutBackDialog} onClose={handelCloseAboutBackgroundModel} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle>{language === 'ar' ? 'طريقة إنشاء خلفية للفيديو' : 'How to add background to video'}</DialogTitle>
        <DialogContent>
          <div style={{textAlign:'center',alignItems:'center'}}>
            <img src='https://samrt-loader.com/add_background.png' alt='add background' width={'100%'} height={200}/>
          </div>
          <Box mt={1} my={2}>
          <video controls style={{ width: '100%', maxHeight: '380px' }}>
            <source src={"https://samrt-loader.com/how_to_backgrond.mp4"} type={"video/mp4"} />
            Your browser does not support the video tag.
          </video>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handelCloseAboutBackgroundModel} color="primary">
            {language === 'ar' ? 'حسنا !' : 'got it !'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteVideoBox} onClose={handleCloseDeleteVideoBox} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'حذف الفيديو' : 'Delete video'}</DialogTitle>
        <DialogContent>
            <Typography textAlign={'center'} color="textSecondary">
               {language === 'ar' ? 'سيتم حذف الفيديو من القائمة, هل تريد الحذف ؟' : 'This video will deleted form your list, Are you sure of delete ?'}
            </Typography>
        </DialogContent>
        <DialogActions>
          <Button aria-label={"close"} onClick={handleCloseDeleteVideoBox} color="secondary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
          <Button aria-label={"delete"} disabled={loadingDeleteVideoBox} onClick={handleDeletVideoBox} color="error">            
            {loadingDeleteVideoBox ? (
                    <span>
                        <CircularProgress size={24} />
                    </span>
                ) : (
                    <span>
                        {language === 'ar' ? 'حذف' : 'Delete'}
                    </span>
                )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openDeleteEditerBox} onClose={handleCloseDeleteEditerBox} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'حذف الموضوع' : 'Delete project'}</DialogTitle>
        <DialogContent>
            <Typography textAlign={'center'} color="textSecondary">
               {language === 'ar' ? 'سيتم حذف الموضوع ولن تتمكن من إستعادة البيانات, هل تريد الحذف ؟' : 'This project will deleted for ever and you will be unable to restore it, Are you sure of delete ?'}
            </Typography>
        </DialogContent>
        <DialogActions>
          <Button aria-label={"close"} onClick={handleCloseDeleteEditerBox} color="secondary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
          <Button aria-label={"delete"} disabled={loadingDeleteEditerBox} onClick={() => handleDeletEditerBox(selectedProjectID)} color="error">            
            {loadingDeleteEditerBox ? (
                    <span>
                        <CircularProgress size={24} />
                    </span>
                ) : (
                    <span>
                        {language === 'ar' ? 'حذف' : 'Delete'}
                    </span>
                )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openAddEditerModal} onClose={handleCloseAddEditerModal} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'إضافة موضوع' : 'Add new project'}</DialogTitle>
        <DialogContent>
          <TextField
            margin="dense"
            label={language === 'ar' ? 'عنوان الموضوع' : 'Project name'}
            type="text"
            fullWidth
            variant="outlined"
            value={projectName}
            onChange={handleProjectNameChange}
          />
        </DialogContent>
        <DialogActions>
          <Button aria-label={"close"} onClick={handleCloseAddEditerModal} color="secondary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
          <Button aria-label={"add"} disabled={loadingAddEditBox} onClick={handleAddNewEditerBox} color="success">            
            {loadingAddEditBox ? (
                    <span>
                        <CircularProgress size={24} />
                    </span>
                ) : (
                    <span>
                        {language === 'ar' ? 'إضافة' : 'Add'}
                    </span>
                )}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openTextModle} onClose={handleCloseTextModel} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'إضافة نص' : 'Add text'}</DialogTitle>
        <DialogContent>
            <Typography textAlign={'center'} color="textSecondary">
               {language === 'ar' ? 'أدخل النص الذي تود ظهوره على المقطع' : 'Add your text to show on current frame'}
            </Typography>
            <CustomTextField
                label={language === 'ar' ? 'أدخل النص للظهور على المقطع' : 'Enter text to add on canvas'}
                variant="outlined"
                value={userText}
                type="text"
                name="addtext"
                onChange={handleTextChange}
                margin="normal"
                inputProps={{ dir: language === 'ar' ? 'rtl' : 'ltr' }}
            />
        </DialogContent>
        <DialogActions>
          <Button aria-label={"close"} onClick={handleCloseTextModel} color="secondary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
          <Button aria-label={"generate"}  onClick={handleGenerateText} color="success">            
            {language === 'ar' ? 'إضافة' : 'Add text'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openSoundModle} onClose={handleCloseSoundModel} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'إضافة صوت' : 'Add sound'}</DialogTitle>
        <DialogContent textAlign={'center'} alignItems={'center'}>
            <div>
              {userAudioURL !== '' ? (
                 <AudioPlayer audioUrl={userAudioURL} onAudioClick={(isPlay,audioRefSet) => handelIsPlay(isPlay,audioRefSet)}/>
              ) : (
                 <SettingsVoiceIcon style={{width:'100%' ,height:300}}/>
              )}
            </div>
            &nbsp;
            <div style={{textAlign:'center'}}>
            {userAudioURL !== '' ? (
                <ButtonGroup variant="contained" color="inherit" aria-label="outlined button group">
                  <Button aria-label={"Add sound"} variant="contained" color="inherit" onClick={handleGenerateAddSoundProcess}>
                    {language === 'ar' ? 'تغيير الصوت' : 'Change sound'}
                  </Button>
                  <Button aria-label={"delete sound from list"} variant="contained" color="error" onClick={handleDeletAudioBox}>
                      <DeleteForeverIcon />
                  </Button>
                </ButtonGroup>
              ) : (
                  <Button aria-label={"Add sound"} variant="contained" color="success" onClick={handleGenerateAddSoundProcess}>
                      {language === 'ar' ? 'إضافة صوت' : 'Add sound'}
                  </Button>
              )}
                                <input
                                  type="file"
                                  accept="audio/mp3, video/mp4"
                                  ref={fileInputRef}
                                  style={{ display: 'none' }}
                                  onChange={handleFileChange}/>
            </div>
        </DialogContent>
        <DialogActions>
          <Button aria-label={"close"} onClick={handleCloseSoundModel} color="secondary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog open={openBackgroundModle} onClose={handleCloseBackgroundModel} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'إضافة خلفية' : 'Add Background'}&nbsp;<HelpOutlineIcon onClick={handelOpenAboutBackgroundModel}/></DialogTitle>
        <DialogContent textAlign={'center'} alignItems={'center'}>
            <Typography textAlign={'center'} color="textSecondary" marginBottom={5}>
               {language === 'ar' ? 'خلفية الفيديو' : 'Video background'}
            </Typography>
            <div>
              {backgroundUrl !== '' ? (
                <img src={backgroundUrl} alt='background video' width={'100%'} height={300} />
              ) : (
                 <CenterFocusWeakIcon style={{width:'100%' ,height:300}}/>
              )}
            </div>
            &nbsp;
            <div style={{textAlign:'center'}}>
            {backgroundUrl !== '' ? (
                <ButtonGroup variant="contained" color="inherit" aria-label="outlined button group">
                  <Button aria-label={"change image"} onClick={handleGenerateAddBackGroundProcess} color="inherit">
                    {language === 'ar' ? 'تغيير الصورة' : 'Change image'}
                  </Button>
                  <Button aria-label={"delete image"} onClick={handleDeletImageBox} color="error">
                    <DeleteForeverIcon />
                  </Button>
                </ButtonGroup>
              ) : (
                <Button aria-label={"add image"} onClick={handleGenerateAddBackGroundProcess} color="success">
                  {language === 'ar' ? 'إضافة صورة' : 'Add image'}
                </Button>
              )}
            <input
              type="file"
              accept="image/*"
              ref={imagefileInputRef}
              style={{ display: 'none' }}
              onChange={handleImageFileChange}/>
            </div>
        </DialogContent>
        <DialogActions>
          <Button aria-label={"close"} onClick={handleCloseBackgroundModel} color="secondary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={openStickerModle} onClose={handleCloseStickerModel} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'إنشاء ستيكر' : 'Generate a sticker'}</DialogTitle>
        <DialogContent>
            <Typography textAlign={'center'} color="textSecondary">
               {language === 'ar' ? 'إختر نوع الستيكر الذي تريد إنشائه' : 'Choose your ganerated sticker type'}
            </Typography>
            <div>
            <FormControl>
      <RadioGroup
        defaultValue="False"
        aria-labelledby="sticker-customized-radios"
        name="customized-radios"
      >
        <FormControlLabel checked={removeBackGround === 'False'} onChange={handleChangeBackgroundType} value="False" control={<BpRadio />} label={language == 'ar' ? 'مع الخلفية' : 'with background'} />
        <FormControlLabel checked={removeBackGround === 'true'} onChange={handleChangeBackgroundType} value="true" control={<BpRadio />} label={language == 'ar' ? 'من دون الخلفية' : 'without background'} />
      </RadioGroup>
      </FormControl>
    </div>
        </DialogContent>
        <DialogActions>
          <Button aria-label={"close"} onClick={handleCloseStickerModel} color="secondary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
          <Button aria-label={"generate"}  onClick={() => handleGenerateProcess('video_to_gif')} color="success">            
            {language === 'ar' ? 'إنشاء' : 'Generate'}
          </Button>
        </DialogActions>
      </Dialog>
        </div>
    );
};

export default CustumVideoEditer;
