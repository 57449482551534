import React, { useState } from 'react';
import Slider from 'react-slick';
import { Typography , Link , Card, IconButton , CardMedia, Button, Box , Dialog , DialogTitle , DialogContent , DialogActions , CircularProgress } from '@mui/material';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useLanguage } from './LanguageContext';
import ReactPlayer from 'react-player';
import useSweetAlert from './SweetAlert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Custom Previous Arrow
const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <IconButton
        style={{
          position: 'absolute',
          left: '-20px',  // Adjust this value to align with your design
          top: '50%',
          transform: 'translate(0, -50%)',
          zIndex: 2,
        }}
        onClick={onClick}
      >
        <ArrowBackIosIcon />
      </IconButton>
    );
  };
  
  // Custom Next Arrow
  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <IconButton
        style={{
          position: 'absolute',
          right: '-20px',  // Adjust this value to align with your design
          top: '50%',
          transform: 'translate(0, -50%)',
          zIndex: 2,
        }}
        onClick={onClick}
      >
        <ArrowForwardIosIcon />
      </IconButton>
    );
  };
  
  const Carousel = ({ data }) => {
    const settings = {
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 3000,
      slidesToShow: 10,
      slidesToScroll: 1,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 500, // For screens <= 500px
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 1024, // For screens > 500px
          settings: {
            slidesToShow: 10,
          },
        },
      ],
    };

    const [showShareDialog, setShowShareDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [shareData, setShareData] = useState([]);
    const { language } = useLanguage();
    const { showAlert } = useSweetAlert();
    const [loadingDown, setLoadingDown] = useState(false);

    const [openAccount, setOpenAccount] = useState(false);
    const [isLoadAccount, setIsLoadinAccount] = useState(false);
    const [userPhotoURL, setUserPhoto] = useState('https://samrt-loader.com/kydwon/smartloader.webp');
    const [userName, setUserName] = useState('User');
    const [userFaceBookAccount, setFaceBookAccount] = useState('None');
    const [userInstaAccount, setInstaAccount] = useState('None');
    const [userSnapAccount, setSnapAccount] = useState('None');
    const [userTikTokAccount, setTikTokAccount] = useState('None');
    const [userTwitAccount, setTwitAccount] = useState('None');
    const styles = {
        image: {
          width: '100px',
          height: '100%',
          borderRadius: '50%',
          objectFit: 'cover',
        },
      };

    const handleCloseAccount = () => {
        setOpenAccount(false);
    }

    const shawShare = async(shareID) => {
        setShowShareDialog(true);
        try {
            setLoading(true);
            const response = await fetch(`https://samrt-loader.com/kydwon/api/view/share_box_view2/${shareID}`);
            const data = await response.json();
            
            if (data.success) {
              setShareData(data.share_box_view2);
            } else {
              console.error('Failed to fetch share video');
              setIsError(true);
            }
          } catch (error) {
            console.error('Error:', error);
            setIsError(true);
          }
          setLoading(false);
    }

    const handleCloseShareDialog = () => {
        setShowShareDialog(false);
    }

    const openVideoSrc = async(videoUrl) => {
        const checkIsLogin = localStorage.getItem('isLogin');
        if (checkIsLogin === "true") {
            window.open(videoUrl , '_blank')
        } else {
            showAlert({
                text: language === 'ar' ? 'الرجاء تسجيل الدخول للتحميل' : 'Please login to download',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start'
              });
        }
      }
    
      const openUserInfoModel = async(userID) => {
        try{
            setOpenAccount(true);
            setIsLoadinAccount(true);
            const formData = new FormData();
            formData.append('user_id', userID);
            const res = await fetch('https://samrt-loader.com/kydwon/api/getUserInfo' , {
              method: 'POST', // Specify the method
              headers: {
                 'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
              },
              body: formData
            });
            const data = await res.json();
            if(data.success) {
                if(data.user_photo_url){
                    setUserPhoto(data.user_photo_url)
                }
                if(data.user_name){
                    setUserName(data.user_name)
                }
                if(data.facebook_account){
                    setFaceBookAccount(data.facebook_account)
                }
                if(data.insta_account){
                    setInstaAccount(data.insta_account)
                }
                if(data.snap_account){
                    setSnapAccount(data.snap_account)
                }
                if(data.twit_account){
                    setTwitAccount(data.twit_account)
                }
                if(data.tiktok_account){
                    setTikTokAccount(data.tiktok_account)
                }
            } else {
                showAlert({
                    text: language === 'ar' ? 'الرجاء تسجيل الدخول أولا' : 'Please login first!',
                    icon: 'error',
                    position: language === 'ar' ? 'top-end' : 'top-start'
                  });
                  handleCloseAccount();
            }
        } catch (error) {
            showAlert({
                text: language === 'ar' ? 'الرجاء تسجيل الدخول أولا' : 'Please login first!',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start'
              });
              handleCloseAccount();
        } finally {
            setIsLoadinAccount(false);
        }
      }

    const handleOpenViewDownloadModal = async(videoID,fileUrl) => {
        const checkIsLogin = localStorage.getItem('isLogin');
        if (checkIsLogin === "true") {
            try{
                setLoadingDown(true);
                const formData = new FormData();
                formData.append('video_id', videoID);
                const res = await fetch('https://samrt-loader.com/kydwon/api/updateDownload' , {
                  method: 'POST', // Specify the method
                  headers: {
                     'X-Authorization':`Bearer ${localStorage.getItem('authToken')}`
                  },
                  body: formData
                });
                const data = await res.json();
                if(data.success){
                    window.location.href = fileUrl;
                } else {
                    showAlert({
                        text: language === 'ar' ? 'الرجاء تحديث الصفحة وإعادة المحاولة' : 'Please refresh page and try agian !',
                        icon: 'error',
                        position: language === 'ar' ? 'top-end' : 'top-start'
                      });
                }
            } catch (error) {
                showAlert({
                    text: language === 'ar' ? 'الرجاء تحديث الصفحة وإعادة المحاولة' : 'Please refresh page and try agian !',
                    icon: 'error',
                    position: language === 'ar' ? 'top-end' : 'top-start'
                  });
            } finally {
                setLoadingDown(false);
            }
        } else {
            showAlert({
                text: language === 'ar' ? 'الرجاء تسجيل الدخول للتحميل' : 'Please login to download',
                icon: 'error',
                position: language === 'ar' ? 'top-end' : 'top-start'
              });
        }
      }

  return (
    <div>
      <Slider {...settings}>
       {data.map((item) => (
         <Box key={item.share_id} sx={{ padding: 2 }}>
          <Card sx={{ maxWidth: 50, maxHeight: 100 ,borderRadius:'50%'}}>
            <CardMedia 
              onClick={() => shawShare(item.share_id)}
              style={{borderRadius:'50%'}}
              component="img"
              height="50"
              image={item.file_cover_url}
              alt={`daily share ${item.share_id}`}
            />
          </Card>
        </Box>
       ))}
      </Slider>

      {/* Dialog Daily Share */}
      <Dialog open={showShareDialog} onClose={handleCloseShareDialog} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogContent style={{width:'300px'}}>
        {loading ? (
            <Box width="100%" minWidth={'300px'} my={5} textAlign={'center'}>
                <CircularProgress color='error' size={24} />
            </Box>
          ) : (
            <div>
                {isError ? (
                   <Box width="100%" minWidth={'300px'} my={5} textAlign={'center'}>
                      <Typography color="error">{language === 'ar' ? 'لم يتم تحميل المشاركة, برجاء المحاولة لاحقا!' : 'An error while loading share, Please try again later!'}</Typography>
                   </Box>
                ) : (
                    <Box mt={1} my={2}>
                    <ReactPlayer url={shareData.share_url} controls={true}
                                     config={{
                                      file: {
                                         attributes: {
                                             controlsList: 'nodownload', // This hides the download button
                                          },
                                      }}} width="100%" height="300px" style={{ maxHeight:'300px' }} />
                                                <p>{shareData.share_comment}</p>
          <p>
          <Link onClick={() => openUserInfoModel(shareData.share_by_user_id)} color="inherit" className="animated-link">
              {language === 'ar' ? 'بواسطة : ' : 'by user : '}{shareData.share_by_user_name}
          </Link>
          </p>
          <p>
          <Link onClick={() => openVideoSrc(shareData.orginal_share_src_url)} color="inherit" className="animated-link">
              {language === 'ar' ? 'رابط المصدر' : 'Src url'}
          </Link>
          </p>
                    </Box>
                )}
            </div>
          )}
        </DialogContent>
        {loading ? (
            <div>
            </div>
        ) : (
            <DialogActions>
            <Button onClick={handleCloseShareDialog} color="primary">
              {language === 'ar' ? 'إغلاق' : 'Close'}
            </Button>
            {!isError && (
            <Button disabled={loadingDown} aria-label={"download_share"} variant="contained" color="success" onClick={() => handleOpenViewDownloadModal(shareData.share_id , shareData.share_url)} download>
            {loadingDown ? (
                  <div>
                     <CircularProgress size={24} />&nbsp;&nbsp;{language === 'ar' ? 'جار التحميل ...' : 'Downloading...'}
                  </div>
              ) : (
                  <div>
                     <FontAwesomeIcon icon="fa-solid fa-download" />&nbsp;&nbsp;{language === 'ar' ? 'تحميل' : 'Download'} {shareData.share_comments}
                  </div>
              )}
            </Button> 
            )}
          </DialogActions>
        )}                       
      </Dialog>

      {/* Dialog Account */}
      <Dialog open={openAccount} onClose={handleCloseAccount} style={{ direction: language === 'ar' ? 'rtl' : 'ltr' }}>
        <DialogTitle textAlign={'center'}>{language === 'ar' ? 'عرض ملف المستخدم' : 'View account info'}</DialogTitle>
        <DialogContent>
          {isLoadAccount ? (
            <Box width="100%" minWidth={'300px'} my={5} textAlign={'center'}>
                <CircularProgress color='error' size={24} />
            </Box>
          ) : (
            <Box width="100%" my={5} alignItems={'center'} textAlign={'center'}>
                    <img
                       src={userPhotoURL}
                        alt={'user image'}
                        style={styles.image}
                    />
              &nbsp;
              <p>{ userName }</p>
              <Box width="100%" minWidth={'300px'} my={2} textAlign={'left'}>
                   <p><img src="https://samrt-loader.com/social_icons/facebook.png" height={'20px'} width={'20px'} alt='facebook'/>&nbsp;&nbsp;{userFaceBookAccount}</p>
                   <p><img src="https://samrt-loader.com/social_icons/instagram.png" height={'20px'} width={'20px'} alt='instagram'/>&nbsp;&nbsp;{userInstaAccount}</p>
                   <p><img src="https://samrt-loader.com/social_icons/snapchat.png" height={'20px'} width={'20px'} alt='snapchat'/>&nbsp;&nbsp;{userSnapAccount}</p>
                   <p><img src="https://samrt-loader.com/social_icons/tiktok.png" height={'20px'} width={'20px'} alt='tiktok'/>&nbsp;&nbsp;{userTikTokAccount}</p>
                   <p><img src="https://samrt-loader.com/social_icons/twitter.png" height={'20px'} width={'20px'} alt='twitter'/>&nbsp;&nbsp;{userTwitAccount}</p>
              </Box>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAccount} color="primary">
            {language === 'ar' ? 'إغلاق' : 'Cancel'}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Carousel;
